import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { signIn, signOut, useSession } from 'next-auth/react';
import LoadingIndicator from '../LoadingIndicator';

const AutoLogin = () => {
  const router = useRouter();
  const { data: session, status } = useSession();
  const [confirmationCode] = useState(router.query?.code || router.query?.c || '');
  const [zipCode] = useState(router.query?.zip || '');
  const [autoLoginLoading, setAutoLoginLoading] = useState(false);

  useEffect(() => {
    if (status === 'loading') {
      // If session is still loading do nothing
      if (autoLoginLoading && session) {
        // Auto login has completed, replace URL in browser to remove query params
        router.replace(router.pathname, undefined, { shallow: true });
        return;
      }
      if (session && status === 'authenticated') {
        // If session exists, logout
        signOut({ callbackUrl: `${window.location.origin}${router.asPath}` });
      } else {
        // Session doesn't exist, login
        setAutoLoginLoading(true);
        signIn('credentials', {
          redirect: false,
          zipCode,
          confirmationCode,
        });
      }
    }
  }, [session, status]);

  return <LoadingIndicator loadingReason='autoLogin' />;
};

export default AutoLogin;
